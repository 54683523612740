import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import VideoStudioPage from "./pages/VideoStudio";
import 'theta-video-api-drm-player/dist/index.css'

function App() {
  return (
    <div className={'App'}>
      <Switch>
        <Route path="/video/:videoId" component={VideoStudioPage} exact/>
      </Switch>
    </div>  );
}

export default App;
