import {createBrowserHistory} from 'history';

// ===========================
// HISTORY
// ===========================
export const history = (() => {
  let h = {};
  try {
    h = createBrowserHistory();
  } catch (e) {

  }
  return h;
})();


// ===========================
// HELPERS
// ===========================

export const back = () => history.back?.()
