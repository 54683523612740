import React from 'react';
import {useLocation, useParams} from "react-router";
import 'theta-video-api-drm-player/dist/index.css'
import {StudioPlayer} from "theta-video-api-drm-player";
import {ENV} from "../constants";

function VideoStudioPage() {
    const {videoId} = useParams();
    const queryParams = new URLSearchParams(useLocation().search)
    const jwt = queryParams.get('jwt')
    const useBeta = ENV === 'beta'

    const wcConfig = {
        "projectId": "13f18a90044df63f7782996fd254a108",
        "appName": "TVA Player"
    }

    return (
        <StudioPlayer
            videoId={videoId}
            walletConnectParams={wcConfig}
            params={{useBeta, jwt}}
        />
    );
}


export default VideoStudioPage;
